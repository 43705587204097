<template>
	<v-container fluid class="border-bottom pa-0 ma-0 max-height-bar" :class="appContainerHeaderClass">
		<v-row justify="space-between" no-gutters>
			<v-col cols="9" class="d-flex max-height-bar">
				<v-toolbar-title class="mappanelheadertitle fw-semi">
					{{ organizationName }}
					<!-- {{ userName }} -->
					<span v-if="profile" class="fw-semi"> ({{ profile }}) </span>
					<!-- <span v-if="organizationName" class="fw-semi"> ({{ organizationName }}) </span> -->
				</v-toolbar-title>
			</v-col>
			<v-col cols="3" class="d-flex justify-end max-height-bar pr-2">
				<v-toolbar-items class="right-menu">
					<screenfull id="screenfull" class="right-menu-item hover-effect" />
					<div v-if="profile != superAdminProfile" style="max-width: 5rem; height: 48px" class="avatar-wrapper pl-2">
						<img :src="image" :title="organizationName" @click="callUrl()" class="user-avatar rounded sizing-menu" />
					</div>
					<div v-else class="ml-2">
						<v-menu min-width="180" offset-y bottom left nudge-bottom="10">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									class="mr-0"
									style="max-width: 5rem; height: 48px; border-radius: 0px !important"
									icon
									v-bind="attrs"
									v-on="on"
								>
									<img :src="image" :title="organizationName" class="user-avatar rounded sizing-menu" />
								</v-btn>
							</template>
							<v-list>
								<!--
								<div class="text-h5 grey--text text--darken-3 px-4 pt-4">John Smith</div>
								<div class="subtitle-2 primary--text font-weight-regular px-4">Flatlogic.com</div>
								-->
								<v-list-item-group :color="primaryColor">
									<v-list-item v-for="(item, i) in options" :key="i">
										<v-list-item-icon class="mr-4">
											<img :src="item.image" style="transform: scale(0.75); width: 50px" />
										</v-list-item-icon>

										<v-list-item-content>
											<v-list-item-title @click="superadmin(item)">{{ item.text }}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
								<!--
								<div class="d-flex justify-center my-3">
									<v-btn width="80%" large outlined :color="primaryColor" class="text-capitalize" @click="logOut">Sign Out </v-btn>
								</div>
								-->
							</v-list>
						</v-menu>
					</div>
				</v-toolbar-items>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {
	search,
	getAreas,
	getDatasourcetype,
	getStringFeatureImage,
	filterDependOnRole,
	isSuperAdmin,
	getIconsTypeIcon,
	/* getIconsType, */
	setRules,
	setTimeunits,
	setErrorCodes
} from '@/api/common';
import { SETTINGS_PANEL_ID } from '@/lib/variables/panels';

import Screenfull from '@/components/ui/Screenfull';
//import station from '@/assets/eiffel/logo-eiffel.png';

import elasticsearchImage from '@/layout/images/elasticsearch.png';
import kibanaImage from '@/layout/images/kibana.png';
import swaggerImage from '@/layout/images/swagger.png';
// eslint-disable-next-line no-unused-vars
import { NOTIFICATIONS_LAYER, setNotificationsLayerConstants } from '../lib/constants/layers';
import constants from '@/utils/constants';

//import geoserverImage from '@/layout/images/geoserver.png';

export default {
	name: 'Layout',
	components: {
		Screenfull
	},
	/*props: {
		organizationName: {
			type: String,
			required: true
		},
		profile: {
			type: String,
			required: true
		} ,
		height: {
			type: Number,
			required: true,
			value: '48px'
		}
	},*/
	data() {
		return {
			image: '',
			options: [
				{
					text: 'Swagger',
					value: 'swagger',
					image: swaggerImage,
					selected: false,
					url: 'https://eiffel-sp.prodevelopataws.com/sp/swagger-ui/#/'
				},
				{
					text: 'Elastic',
					value: 'elastic',
					image: elasticsearchImage,
					selected: false,
					url: 'https://eiffel-sp.prodevelopataws.com/elastic'
					/*virtual: true,
					hidden: false,
					color: '#25422b'*/
				},
				{
					text: 'Kibana',
					value: 'kibana',
					image: kibanaImage,
					selected: false,
					url: 'https://eiffel-sp.prodevelopataws.com/kibana'
				} /*,
				{
					text: 'GeoServer',
					value: 'geoserver',
					image: geoserverImage,
					selected: false
				}*/
			],
			notificationsLayer: null,
			superAdminProfile: constants.SUPER_ADMIN_ROLE,
			notificationsLayerConstants: {},
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	computed: {
		expandMenu() {
			return this.$store.state.menu.expandMenu;
		},
		secondMenuShowed() {
			return this.$store.state.menu.secondMenuShowed;
		},
		menuHidden() {
			return this.$store.state.menu.hideMenu;
		},
		appContainerHeaderClass() {
			return {
				'app-containerHeader': true,
				'app-containerHeader--menu-opened': this.expandMenu || this.secondMenuShowed,
				'app-containerHeader--menu-hidden': this.menuHidden
			};
		},
		organizationName() {
			return this.userProperties ? this.userProperties.organizationname : '';
		},
		profile() {
			return this.userProperties ? this.userProperties.profile : '';
		},
		userName() {
			return this.userProperties ? this.userProperties.name : '';
		}
	},
	watch: {},
	beforeCreate() {
		//console.log('=> beforeCreate - AppContainerHeader');
	},
	created() {
		//console.log('=> created - AppContainerHeader');
		try {
			const promise = this.getPropertiesByUser();
			promise.then((result) => {
				this.getUserRelatedData(result[0]);
				this.$store.commit('setContainerHeaderVisibility', true);
			});
			this.$store.commit('setActivePanel', { panelId: SETTINGS_PANEL_ID });
			this.$store.commit('setPanelsClosed', false);
		} catch (e) {
			console.error('ERROR: Loading process -> ', e);
		}
	},
	beforeMount() {
		//console.log('=> beforeMount - AppContainerHeader');
		this.$store.commit('setContainerHeaderVisibility', true);
	},
	mounted() {
		console.log('debug session & userProperties', this.session, this.userProperties);
		if (!this.session.usr) {
			console.log('Login page. requests paused', this.session);
		} else {
			console.log('User properties: ', this.userProperties, this.session);
		}

		var route = this.$router.history.current.fullPath.toString();
		console.log('===================================');
		console.log('ROUTE: ' + route);
	},
	beforeUpdate() {},
	updated() {
		//console.log('=> updated - AppContainerHeader');
	},
	beforeDestroy() {},
	destroyed() {
		//console.log('=> destroyed - AppContainerHeader');
		this.cleanSessionStore();
	},
	methods: {
		async getPropertiesByUser() {
			const filterUser = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'usr', op: 'eq', data: this.session.usr },
					{ field: 'idiom', op: 'eq', data: this.session.language }
				]
			};

			const body = {
				model: 'pmusers',
				filter: filterUser,
				rows: 1
			};

			const { data } = await search(body);
			console.log('RESULT (getPropertiesByUser)', data.data[0]);
			data.data.length > 0 ? this.$store.commit('setUserProperties', data.data[0]) : this.$store.commit('setUserProperties', null);
			console.log('debug userProps', this.userProperties);

			return data.data;
		},

		async getUserRelatedData(userProperties) {
			Promise.all([
				this.getRefreshTime(),
				/* this.getActiveAreas(userProperties), */
				this.getSeverities(),
				/* this.getActiveDatasourcetype(userProperties), */
				/* this.getParametersData(userProperties.organizationid, isSuperAdmin(this.session.profiles[0])), */
				this.hasAqiPoints(userProperties),
				this.hasDevices(userProperties),
				this.hasLayers(userProperties)
			]).then((values) => {
				console.warn('relatedData promises', values);
				this.redirectToMap(userProperties);
			});
		},

		async getRefreshTime() {
			const filterUser = filterDependOnRole(this.userProperties);
			const body = {
				model: 'pmrefreshtime',
				filter: filterUser
			};

			const { data } = await search(body);
			if (data.data[0]) {
				var deliveryperiod = data.data[0].refreshtime;
			} else {
				deliveryperiod = null;
			}
			console.log('refresh time deliveryperiod 1', deliveryperiod, data.data, body);
			this.$store.commit('setRefreshTime', deliveryperiod);
			return data;
		},

		async getActiveAreas(userProperties) {
			var areas = getAreas(this.session.language, userProperties);
			areas.then((result) => {
				this.$store.commit('setAreas', result.data);
				return result;
			});
		},

		async getActiveDatasourcetype(userProperties) {
			var dtsTypes = getDatasourcetype(this.session.language, userProperties);
			dtsTypes.then((result) => {
				this.$store.commit('setDatasourcetype', result.data);
				return result;
			});
		},

		async getParametersData(organizationid) {
			var rulesFilter = [];

			if (isSuperAdmin(this.session.profiles[0])) {
				rulesFilter = [
					//{ field: 'pmdatasourcetypeid', op: 'eq', data: this.featurePopup.pmdatasourcetypeid },
					//{ field: 'pmorganizationid', op: 'eq', data: this.featurePopup.pmorganizationid },
					//{ field: 'lang', op: 'eq', data: this.userProperties.language },
					{ field: 'disabled', op: 'eq', data: 0 }
				];
			} else {
				rulesFilter = [
					//{ field: 'pmdatasourcetypeid', op: 'eq', data: this.featurePopup.pmdatasourcetypeid },
					{ field: 'pmorganizationid', op: 'eq', data: organizationid },
					//{ field: 'lang', op: 'eq', data: this.userProperties.language },
					{ field: 'disabled', op: 'eq', data: 0 }
				];
			}

			let body = {
				model: 'vluppmparameterdatasourcetype',
				filter: {
					groups: [],
					groupOp: 'and',
					rules: rulesFilter
				},
				rows: 999
			};
			let { data } = await search(body);
			if (data) {
				data.data.sort((a, b) => {
					const acronymA = a.acronym.toUpperCase();
					const acronymB = b.acronym.toUpperCase();
					if (acronymA < acronymB) {
						return -1;
					}
					if (acronymA > acronymB) {
						return 1;
					}
					return 0; // Objects have the same acronym, no need to change their order
				});

				//console.log('After get parameters', data.data);
				this.$store.commit('setParameters', data.data);
			} else {
				this.$store.commit('setParameters', []);
				//console.log('no parameter data');
			}

			return data;
		},

		async hasDevices(userProperties) {
			var filterDevice = null;
			if (this.profile != this.superAdminProfile) {
				filterDevice = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 }
					]
				};
			}

			let body = {
				model: 'vlupdevicespatialmodel',
				filter: filterDevice,
				rows: 999
			};
			let { data } = await search(body);
			this.$store.commit('setDevices', data.data);
			this.$store.commit('setDevicesData', data.data);
			this.$store.commit('setHasDevices', Object.keys(data.data).length > 0);

			return data;

			////console.warn('hasDevices AppContainerHeader: ' + data.data.length);
			////console.warn(data.data);
		},

		async hasAqiPoints(userProperties) {
			var filterAqiPoint = null;
			if (this.profile != this.superAdminProfile) {
				filterAqiPoint = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 }
					]
				};
			}

			let body = {
				model: 'pmaqipoints',
				filter: filterAqiPoint,
				rows: 999
			};
			let { data } = await search(body);
			this.$store.commit('setAqiPoints', data.data);
			this.$store.commit('setHasAqiPoints', Object.keys(data.data).length > 0);

			return data;

			////console.warn('hasAqiPoints AppContainerHeader: ' + data.data.length);
			////console.warn(data.data);
		},

		async hasLayers(userProperties) {
			let filter = null;
			if (this.profile != this.superAdminProfile) {
				filter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 },
						{ field: 'datasourcetypedisabled', op: 'eq', data: 0 }
					]
				};
			}

			let body = {
				model: 'pmspatialtables',
				filter: filter,
				rows: 999
			};
			let { data } = await search(body);
			//this.$store.commit('setSpatialTables', data.data);
			this.$store.commit('setHasLayers', data.data.length > 0);

			//console.warn('hasSpatialTables AppContainerHeader: ', data.data, data.data.length, Object.keys(data.data).length);

			return data;
		},

		callUrl() {
			////console.log('function callUrl');
			const urlOrganization = this.userProperties.url;
			////console.log(urlOrganization);
			if (urlOrganization) {
				window.open(urlOrganization, '_blank');
			}
		},

		superadmin(item) {
			////console.log('Prueba');
			////console.log(item);
			window.open(item.url, '_blank');
		},

		// eslint-disable-next-line no-unused-vars
		onSelectChange(e) {
			//console.log('selectChange');
			//console.log(e);
			/*const index = e.target.selectedIndex;
			this.options = this.options.map((o, i) => {
				if (i === index) {
					o.selected = false;
				} else {
					o.selected = false;
				}
				return o;
			});*/
		},
		cleanSessionStore() {
			//this.$store.dispatch('resetState');
			console.info('clean session store');
			this.$store.commit('setContainerHeaderVisibility', false);
			this.notificationsLayer = null;
			this.$store.commit('setUserProperties', null);
			this.$store.commit('setDevices', null);
			this.$store.commit('setAqiPoints', null);
			this.$store.commit('resetNotificationsData');
			this.$store.commit('setSelectedTabAdvanced', 'advanced-device');
			//delete this.$store.state.data[NOTIFICATIONS_LAYER];
		},

		redirectToMap(userProperties) {
			let urlBase = this.$store.getters.getUrlBaseIcons;
			this.image = getStringFeatureImage(urlBase, userProperties.route);

			if (!document.getElementById('copernicus-credit-panel')) this.addCreditToUserPanel();
			setRules(this.$store);
			setTimeunits(this.$store);
			setErrorCodes(this.$store);
			this.setFormFilters();
			var route = this.$router.history.current.fullPath.toString();
			console.log('ROUTE: ' + route);
			if (route.includes('/home')) {
				//console.warn('redirect now to map');
				// eslint-disable-next-line no-unused-vars
				this.$router.push('map').catch((err) => {});
			}
		},
		addCreditToUserPanel() {
			const userPanelDiv = document.getElementById('menuUserPanel').firstElementChild.firstElementChild.firstElementChild;
			if (userPanelDiv) {
				const copernicusIcon = getIconsTypeIcon(constants.APP_ICONS_TYPE_ACRONYM, constants.COPERNICUS_ICON, this.$store.getters.getIcons);
				if (document.getElementById('copernicus-credit-panel')) {
					userPanelDiv.removeChild(document.getElementById('copernicus-credit-panel'));
				}
				if (copernicusIcon) {
					const copernicusImg = document.createElement('img');
					copernicusImg.id = 'copernicus-credit-panel';
					copernicusImg.style.width = '200px';
					copernicusImg.style.paddingTop = '18px';
					copernicusImg.setAttribute('src', getStringFeatureImage(this.$store.getters.getUrlBaseIcons, copernicusIcon.route));

					const copernicusLink = document.createElement('a');
					copernicusLink.href = 'https://www.copernicus.eu/en';
					copernicusLink.target = '_blank';

					copernicusLink.appendChild(copernicusImg);
					userPanelDiv.appendChild(copernicusLink);
				}
			}
		},
		async setFormFilters() {
			let bodyFilter = {
				model: 'pmfiltercondition',
				rows: 1000,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: []
				}
			};
			let bodyAreaDef = {
				model: 'pmareadefinition',
				rows: 1000,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
				}
			};

			let dataFilter = await search(bodyFilter);
			let dataDefinition = await search(bodyAreaDef);
			//console.log('After get data - Filter', dataFilter.data.data);
			//console.log('After get data - Area Def', dataDefinition.data.data);
			this.$store.commit('setFormFilters', dataFilter.data.data);
			this.$store.commit('setAreaDefinitions', dataDefinition.data.data);
		},
		async getSeverities() {
			let filter = {
				groups: [],
				groupOp: 'and',
				rules: []
			};

			let body = {
				model: 'pmseverity',
				rows: 999,
				filter: filter
			};

			let { data } = await search(body);
			//console.log('After get data - Severity', data.data, body);
			this.$store.commit('setSeverities', data.data);
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../styles/eiffel-variables.pcss';
.app-containerHeader {
	width: calc(100% - 64px); /*PuiMenuIconBar*/
	&--menu-opened {
		width: calc(100% - 304px); /*VNavigationDrawer*/
	}
	&--menu-hidden {
		width: calc(100%);
	}
}
.mappanelheadertitle {
	font-family: 'Montserrat', sans-serif !important;
	color: #000;
	font-size: 1.1rem !important;
	font-style: normal;
	font-stretch: normal;
	//line-height: normal;
	letter-spacing: normal;
	padding-top: 10px !important;
	display: flex;
	justify-content: center;
	span {
		font-size: 1.1rem !important;
		padding-left: 5px;
	}
}

.border-bottom {
	//border-bottom: 1px #ccc solid !important;
	margin-left: 60px;
}

.max-height-bar {
	max-height: 48px;
}

.sizing-menu {
	max-height: 40px;
	max-width: 4.5rem;
}

.right-menu {
	height: 100%;
	line-height: 50px;

	&:focus {
		outline: none;
	}

	.right-menu-item {
		display: inline-block;
		padding: 0 8px;
		height: 48px !important;
		border-radius: 0px !important;
		font-size: 18px;
		color: #5a5e66;
		vertical-align: text-bottom;

		&.hover-effect {
			cursor: pointer;
			transition: background 0.3s;

			&:hover {
				background: rgba(0, 0, 0, 0.025);
			}
		}
	}

	.avatar-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		//margin-top: 8px !important;

		.user-avatar {
			cursor: pointer;
		}

		.el-icon-caret-bottom {
			cursor: pointer;
			position: absolute;
			right: -20px;
			top: 25px;
			font-size: 12px;
		}
	}
}
@media only screen and (max-width: 576px) {
	.mappanelheadertitle {
		justify-content: start;
		overflow: hidden;
	}
}
</style>
